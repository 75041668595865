<template>
  <div class="payments-index" v-if="showApp">
    <div class="sidebar" aria-label="Sidebar menu">
      <Sidebar />
    </div>
    <Header class="header" />
    <div class="payments-index-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/layout/Sidebar";
import Header from "@/layout/HeaderMobile";

export default {
  name: "PaymentsIndex",
  components: {
    Sidebar,
    Header
  },
  data() {
    return {
      showApp: false
    };
  },
  async created() {
    if (this.$route.path == "/payments/settings") {
      await this.$router.push({ name: "Settings" }).catch(err => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // logError(err);
        }
      });
    } else if (this.$route.path == "/payments/settings/users") {
      await this.$router.push({ name: "Users" }).catch(err => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // logError(err);
        }
      });
    } else if (this.$route.path == "/payments/settings/tags") {
      await this.$router.push({ name: "Tags" }).catch(err => {
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // logError(err);
        }
      });
    }
    this.showApp = true;
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: none;
}

@media (max-width: 991px) {
  .sidebar {
    display: none;
  }
  .header {
    display: block;
  }
}

.payments-index-content {
  @include padding-view;
}
</style>
