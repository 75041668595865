<template>
  <div class="header-mobile">
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand class="header-image"></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="text-left">
          <b-nav-item @click="redirectionClick('Settings')"
            ><router-link :to="{ name: 'Settings' }"
              ><i class="fa fa-cogs fa-sm"></i> Settings
            </router-link></b-nav-item
          >
          <b-nav-item @click="redirectionClick('Emails', 'queue')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'queue' } }"
              ><i class="fa fa-list-ul fa-sm"></i> Queue</router-link
            ></b-nav-item
          >
          <b-nav-item @click="redirectionClick('Emails', 'ocr-processes')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'ocr-processes' } }"
              ><i class="fas fa-robot"></i> OCR</router-link
            ></b-nav-item
          >
          <b-nav-item click="redirectionClick('Emails', 'pending')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'pending' } }"
              ><i class="fa fa-spinner fa-sm"></i> Pending</router-link
            ></b-nav-item
          >
          <b-nav-item @click="redirectionClick('Emails', 'doubtful')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'doubtful' } }"
              ><i class="fas fa-exclamation-triangle"></i> Doubtful</router-link
            ></b-nav-item
          >
          <b-nav-item @click="redirectionClick('Emails', 'in-dispute')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'in-dispute' } }"
              ><i class="fas fa-hourglass-end"></i> In dispute</router-link
            ></b-nav-item
          >
          <b-nav-item @click="redirectionClick('Emails', 'failed')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'failed' } }"
              ><i class="fas fa-times-circle"></i> Failed</router-link
            ></b-nav-item
          >
          <b-nav-item @click="redirectionClick('Emails', 'posted')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'posted' } }"
              ><i class="fas fa-paper-plane"></i> Posted</router-link
            ></b-nav-item
          >
          <b-nav-item @click="redirectionClick('Emails', 'deleted')"
            ><router-link
              :to="{ name: 'Emails', params: { pathName: 'deleted' } }"
              ><i class="fa fa-trash-alt fa-sm"></i> Deleted</router-link
            ></b-nav-item
          >
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="header-line"></div>
  </div>
</template>

<script>
export default {
  name: "HeaderMobile",
  data() {
    return {
      actualQueueName: ""
    };
  },
  methods: {
    redirectionClick(routerName, urlName) {
      this.$router
        .push({ name: routerName, params: { pathName: urlName } })
        .catch(err => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // logError(err);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-info {
  background-color: #17202a !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.header-image {
  content: url("../"+$image-company);
  width: 70px;
  height: auto;
  padding: 0px;
}

a {
  color: #eceff1 !important;
}

.header-line {
  height: 1.5px;
  background-color: #00ff00;
}
</style>
