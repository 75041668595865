<template>
  <div class="sidebar">
    <div class="sidebar__viewport">
      <div class="sidebar__viewport__menu">
        <header class="sidebar__viewport__menu--header">
          <div class="sidebar__viewport__menu--header--img"></div>
        </header>
        <ul class="nav">
          <li
            v-show="rolByUser && rolByUser.rol_id == 3"
            id="settings"
            class="nav--item"
            @click="redirectionClick('Settings')"
          >
            <router-link :to="{ name: 'Settings' }">
              <i class="fa fa-cogs fa-sm"></i> <br />
            </router-link>
            <span class="nav--item--name">Settings</span>
          </li>
          <li
            id="queue"
            class="nav--item"
            @click="redirectionClick('Emails', 'queue')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'queue' } }"
            >
              <i class="fa fa-list-ul fa-sm"></i> <br />
            </router-link>
            <span class="nav--item--name">Queue</span>
          </li>
          <li
            id="ocr-processes"
            class="nav--item"
            @click="redirectionClick('Emails', 'ocr-processes')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'ocr-processes' } }"
            >
              <i class="fas fa-robot"></i> <br />
            </router-link>
            <span class="nav--item--name">OCR</span>
          </li>
          <li
            id="pending"
            class="nav--item"
            @click="redirectionClick('Emails', 'pending')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'pending' } }"
            >
              <i class="fa fa-spinner fa-sm"></i> <br />
            </router-link>
            <span class="nav--item--name">Pending</span>
          </li>
          <li
            id="doubtful"
            class="nav--item"
            @click="redirectionClick('Emails', 'doubtful')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'doubtful' } }"
            >
              <i class="fas fa-exclamation-triangle"></i> <br />
            </router-link>
            <span class="nav--item--name">Doubtful</span>
          </li>
          <li
            id="in-dispute"
            class="nav--item"
            @click="redirectionClick('Emails', 'in-dispute')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'in-dispute' } }"
            >
              <i class="fas fa-hourglass-end"></i> <br />
            </router-link>
            <span class="nav--item--name">In dispute</span>
          </li>
          <li
            id="failed"
            class="nav--item"
            @click="redirectionClick('Emails', 'failed')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'failed' } }"
            >
              <i class="fas fa-times-circle"></i> <br />
            </router-link>
            <span class="nav--item--name">Failed</span>
          </li>
          <li
            id="posted"
            class="nav--item"
            @click="redirectionClick('Emails', 'posted')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'posted' } }"
            >
              <i class="fas fa-paper-plane"></i> <br />
            </router-link>
            <span class="nav--item--name">Posted</span>
          </li>
          <li
            id="deleted"
            class="nav--item"
            @click="redirectionClick('Emails', 'deleted')"
          >
            <router-link
              :to="{ name: 'Emails', params: { pathName: 'deleted' } }"
            >
              <i class="fa fa-trash-alt fa-sm"></i> <br />
            </router-link>
            <span class="nav--item--name">Deleted</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MenuSidebar",
  data() {
    return {
      pathNames: [
        "settings",
        "queue",
        "ocr-processes",
        "pending",
        "doubtful",
        "in-dispute",
        "failed",
        "posted",
        "deleted"
      ],
      userData: null,
      nameDepartment: null
    };
  },
  computed: {
    ...mapState({
      rolByUser: state => state.users.rolByUserId
    })
  },
  watch: {
    "$route.path": function() {
      this.styleSelectedOption();
    }
  },
  mounted() {
    this.styleSelectedOption();
  },
  methods: {
    styleSelectedOption() {
      let path = this.$route.params.pathName;
      if (this.$route.name == "Tags" || this.$route.name == "Users") {
        for (let i in this.pathNames) {
          document.getElementById(this.pathNames[i]).style.background = "none";
        }
        document.getElementById("settings").style.background =
          "rgba(171, 178, 185, 0.3)";
      } else {
        for (let i in this.pathNames) {
          if (this.pathNames[i] == path) {
            document.getElementById(path).style.background =
              "rgba(171, 178, 185, 0.3)";
          } else {
            document.getElementById(this.pathNames[i]).style.background =
              "none";
          }
        }
      }
    },
    redirectionClick(routerName, urlName) {
      if (routerName == "Settings") {
        this.$router.push({ name: routerName }).catch(err => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            // logError(err);
          }
        });
      } else {
        this.$router
          .push({ name: routerName, params: { pathName: urlName } })
          .catch(err => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // logError(err);
            }
          });
      }
      this.styleSelectedOption();
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar__viewport__menu {
  z-index: 1000;
  position: fixed;
  left: 250px;
  top: 0;
  width: 5%;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: $color-background-dark;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &--header {
    padding: 5% 0%;
    background-color: $color-background-darker;
    line-height: 52px;
    text-align: center;

    &--img {
      @include justify-items-center;
      content: url("../"+$image-company);
      width: 90%;
    }

    a:hover {
      color: $color-white;
    }
  }

  .nav {
    margin-top: 5px;
    text-align: center;

    a {
      background: none;
      color: #cfd8dc;

      &:hover {
        color: $color-background-input;
      }
    }

    i {
      margin-right: 16px;
    }

    &--item {
      width: 100%;
      padding: 8.5% 10%;

      &:hover {
        background: rgba(171, 178, 185, 0.3) !important;
        cursor: pointer;
      }

      &--name {
        font-size: 0.7vw;
        color: $color-light;
      }
    }
  }
}

@media (max-width: 1400px) {
  .sidebar__viewport__menu {
    width: 7%;

    &--header {
      padding: 0;
    }

    .nav--item {
      padding: 5%;

      &--name {
        font-size: 11px !important;
      }
    }
  }
}
</style>
